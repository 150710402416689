import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import useLocation from 'react-use/lib/useLocation'

export type HrefLang = {
  domain: string
  brand_id: string
  url_path: string
  hreflang: string
}

type Props = {
  data: HrefLang[]
}

const Hreflang: React.FC<Props> = ({ data }) => {
  const location = useLocation()
  const { protocol } = location

  const tags = useMemo(() => {
    if (!data) return []
    return data.map(({ domain, hreflang, url_path }) => ({
      href: `${protocol || 'https:'}//${domain}${url_path}`,
      hreflang,
    }))
  }, [data, protocol])

  return (
    <Helmet>
      {tags.map((tag) => (
        <link
          key={tag.href}
          rel="alternate"
          href={tag.href}
          hrefLang={tag.hreflang}
        />
      ))}
    </Helmet>
  )
}

export default Hreflang
